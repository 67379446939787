import * as React from "react"
import { useState, useEffect } from "react"
import { addPropertyControls, ControlType } from "framer"

// Componente Simulador
export function SimuladorMultiplicacionConPorcentaje({
    initialNum1,
    initialNum2,
}) {
    const [num1, setNum1] = useState(initialNum1 || 0)
    const [num2, setNum2] = useState(initialNum2 || 1)
    const [resultado, setResultado] = useState(null)
    const [porcentaje, setPorcentaje] = useState(null)

    // Función para determinar el porcentaje basado en el segundo número
    const obtenerPorcentaje = (num) => {
        if (num >= 0 && num <= 2) return 0
        if (num >= 3 && num <= 5) return 10
        if (num >= 6 && num <= 8) return 15
        if (num >= 9 && num <= 13) return 20
        if (num >= 14 && num <= 18) return 25
        if (num >= 19 && num <= 23) return 30
        if (num >= 24 && num <= 28) return 35
        if (num >= 29 && num <= 33) return 40
        if (num >= 34 && num <= 38) return 45
        return 50
    }

    // Función para realizar la multiplicación y mostrar el porcentaje
    const calcularResultado = () => {
        const porcentajeCalculado = obtenerPorcentaje(num2)
        const multiplicacion = (
            parseInt(num1) *
            (porcentajeCalculado / 100)
        ).toFixed(2)
        setResultado(multiplicacion)
        setPorcentaje(`${porcentajeCalculado}%`)
    }

    // useEffect para recalcular cuando cambian num1 o num2
    useEffect(() => {
        calcularResultado()
    }, [num1, num2])

    return (
        <div style={styles.container}>
            <div style={styles.inputBlock}>
                {/* Entrada del primer número (0 a 6000) */}
                <label style={styles.label}>Monto de tu préstamo</label>
                <input
                    type="number"
                    min="0"
                    max="6000"
                    value={num1}
                    onChange={(e) => setNum1(e.target.value)}
                    style={styles.input}
                    placeholder="0"
                />

                {/* Entrada del segundo número (1 a 60) */}
                <label style={styles.label}>
                    Número de préstamo en el que vas
                </label>
                <input
                    type="number"
                    min="0"
                    max="60"
                    value={num2}
                    onChange={(e) => setNum2(e.target.value)}
                    style={styles.input}
                    placeholder="0"
                />
            </div>

            {/* Bloque de salida */}
            <div style={styles.outputBlock}>
                <div style={styles.resultItem}>
                    <h3>Porcentaje de tus intereses</h3>
                    <p>{porcentaje !== null ? porcentaje : "--"}</p>
                </div>
                <div style={styles.resultItem}>
                    <h3>Tu recompensa</h3>
                    <p>{resultado !== null ? resultado : "--"}</p>
                </div>
            </div>
        </div>
    )
}

// Estilos del simulador
const styles = {
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "left",
        padding: "32px",
        backgroundColor: "#F7F7FB",
        borderRadius: "10px",
        width: "fit-content",
    },
    inputBlock: {
        display: "flex",
        flexDirection: "column",
        alignItems: "left",
        justifyContent: "center",
        marginBottom: "8px", // Ajustar el espacio entre el bloque de entrada y el bloque de salida
    },
    input: {
        marginBottom: "10px",
        padding: "10px",
        fontSize: "16px",
        fontFamily: "'Onest', sans-serif",
        fontWeight: "regular",
        borderRadius: "5px",
        border: "1px solid #F1F1F1",
        width: "250px", // Ancho del input
    },
    button: {
        padding: "10px 20px",
        fontSize: "16px",
        fontFamily: "'Onest', sans-serif",
        fontWeight: "regular",
        backgroundColor: "#007bff",
        color: "white",
        border: "none",
        borderRadius: "5px",
        cursor: "pointer",
        width: "200px", // Ancho del botón igual al del input
        marginBottom: "20px",
    },
    outputBlock: {
        textAlign: "center",
        marginTop: "0px", // Ajustar el espacio entre el bloque de entrada y el bloque de salida
    },
    resultItem: {
        marginTop: "16px",
        padding: "16px",
        backgroundColor: "#fff",
        borderRadius: "5px",
        color: "#404164",
        width: "250px", // Ancho de los resultados igual al del input
        textAlign: "center", // Alineación del texto en los resultados
        fontSize: "12px",
        fontFamily: "'Onest', sans-serif",
        fontWeight: "regular",
    },
    label: {
        fontSize: "14px",
        fontFamily: "'Onest', sans-serif",
        marginBottom: "5px",
        fontWeight: "Regular",
        color: "#404164",
    },
}

// Añadir controles para Framer
addPropertyControls(SimuladorMultiplicacionConPorcentaje, {
    initialNum1: {
        type: ControlType.Number,
        title: "Número 1",
        defaultValue: 0,
        min: 0,
        max: 6000,
    },
    initialNum2: {
        type: ControlType.Number,
        title: "Número 2",
        defaultValue: 1,
        min: 1,
        max: 60,
    },
})
